import React from 'react';
import { ContainerFluid, Row, Col } from '../../UI/Grid/Grid';
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';
import classes from './Footer.module.scss';

const Footer = () => (
    <ContainerFluid className={`${classes.Background} pt-4 pb-4`}>
      <Row>
        <Col className={`col-12 col-lg-6 text-center text-lg-right pr-lg-5`}>
        <a href="https://m.facebook.com/profile.php?id=1291708438&ref=content_filter" target="_blank"><span className='pl-5'>
          <FaFacebook className={classes.Icon}/>
          </span>
        </a>
        <a href="https://www.linkedin.com/in/assem-elkholy" target="_blank">
          <FaLinkedinIn className={classes.Icon}/>
        </a>
        </Col>
        <Col className='col-12 col-lg-6 text-center text-lg-left pl-lg-5'>
          <p className={`${classes.FooterText} pb-0 mb-0 pt-3 pt-lg-0`}>
            <small>
            All Rights Reserved @ ElkholyLawFirm
            </small>
          </p>
        </Col>
      </Row>
    </ContainerFluid>
)

export default Footer;
