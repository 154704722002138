import React from "react";
import PropTypes from "prop-types";

import Header from "./Header/Header";
import Footer from './Footer/Footer';
// import { Transition } from "react-spring/renderprops"

import "./Layout.module.scss"

const Layout = ({ children }) => {

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require

    console.log("location", window.location.hash);
    require("smooth-scroll")('a[href*="#"]', {updateURL: false})
  }

  return (
    <>
      <Header />
        <main>
          {children}
        </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
//
// <main>
//   {children}
// </main>

//
// <Transition
//   config={{ duration: 500, delay: 200 }}
//   from={{ opacity: 0 }}
//   enter={{ opacity: 1 }}
//   leave={{ opacity: 0 }}
// >
//     {() => style => <main style={style}>{children}</main>
